.root {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
}
.root:hover:not(:disabled) {
  color: cadetblue;
}

.root:active {
  background: rgba(220, 220, 220, 0.7);
}

.root:disabled {
  cursor: default;
}

.icon {
  width: 80%;
  height: 80%;
  margin: 10%;
}
