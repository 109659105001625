.root {
  position: fixed;
  /* top: 51px; */
  display: flex;
  flex-direction: column;
  z-index: 200;
  right: 0;
  margin: 10px;
  padding: 5px;
  padding-right: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  border: 1px solid #afafaf;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  width: 360px;
  max-width: calc(100vw - 20px);
  max-height: 90%;
  overflow: auto;
}

@media screen and (max-width: 450px) {
  .root {
    top: 53px;
    transform: none;
    max-width: 100%;
    width: calc(100% - 10px);
    margin: 5px;
  }
}

@media screen and (min-width: 451px) and (max-width: 555px) {
  .root {
    left: 95px;
    width: calc(100% - 115px);
  }
}

@media screen and (min-width: 556px) and (max-width: 615px) {
  .root {
    right: 0;
    width: calc(100% - 175px);
  }
}

@media screen and (min-width: 616px) and (max-width: 755px) {
  .root {
    left: 155px;
  }
}

@media screen and (min-width: 756px) and (max-width: 1220px) {
  .root {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 451px) and (max-width: 1220px) {
  .root {
    top: 57px;
  }
}

@media screen and (min-width: 615px) and (max-width: 1220px) {
  .root {
    width: 440px;
  }
}
