.root {
  margin: 2px 0 2px 0;
  padding: 10px;
  border: 1px solid #afafaf;
  border-radius: 3px;
  background-color: #ffff;
}

.root:focus,
.selected {
  outline: 1px solid #efefef;
  background-color: #d5effcdd;
}

.updated {
  font-style: italic;
  display: none;
}

.root:not(:focus):not(.selected) {
  cursor: pointer;
}

.root:hover:not(:focus):not(.selected) {
  background-color: #d5effc54;
}

.title {
  width: 100%;
  margin: 0;
}
