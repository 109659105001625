#root {
  /* height: calc(100vh - 51px); */
  height: 100%;
  z-index: 100;
}
#root * {
  outline: none;
}

#root :global(.leaflet-popup-content-wrapper) {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  /* border: 1px solid #afafaf; */
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

#root :global(.leaflet-popup-tip) {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
}

#root :global(.leaflet-top.leaflet-left) {
  bottom: 26px;
  top: auto;
}

.loader * {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  z-index: 999;
}
