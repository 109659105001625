.root {
  width: 442px;
  max-width: calc(100vw - 20px);
  position: fixed;
  z-index: 300;
  left: 50%;
  transform: translateX(-50%);
  margin: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  border: 1px solid #afafaf;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.root label {
  cursor: text;
}

.root input {
  width: 400px;
  height: 26px;
  outline: none;
  border: none;
  background: none;
  margin: 10px 20px 10px 20px;
  font-size: x-large;
  color: rgba(80, 80, 80, 0.95);
}

.root input::placeholder {
  color: #afafaf;
}

.root input:not(:focus),
.root input:not(:focus) ~ button {
  color: rgba(80, 80, 80, 0.7);
}

.buttonContainer {
  position: fixed;
  top: 5px;
  right: 5px;
}

.buttonContainer :first-child {
  margin-right: 5px;
}

.buttonContainer button {
  color: rgba(80, 80, 80, 0.95);
}

@media screen and (max-width: 450px) {
  .root {
    margin: 5px;
    left: 105px;
    width: calc(100% - 115px);
  }
}

@media screen and (min-width: 451px) and (max-width: 615px) {
  .root {
    left: 155px;
    width: calc(100% - 175px);
  }
}

@media screen and (min-width: 616px) and (max-width: 755px) {
  .root {
    left: 155px;
  }
}

@media screen and (max-width: 755px) {
  .root {
    transform: none;
  }
}
