.root {
    display: flex;
    color: rgba(80, 80, 80, 0.95);
}

.root #counter {
    position: relative;
    top: 0;
  }

.root button {
    color: rgba(80, 80, 80, 0.95);
}