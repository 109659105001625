.root {
  position: relative;
  max-width: 800px;
  width: 100%;
  height: 100%;
  padding-top: 82px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  margin: auto;
  overflow: auto;
}

.root h1 {
  color: rgba(60, 60, 60, 0.95);
}

.root img {
  max-width: 532px;
  width: 100%;
  height: auto;
  position: relative;
  opacity: 0.7;
}

.root li {
  margin-top: 16px;
}
