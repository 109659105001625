.root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: initial;
  color: rgba(40, 40, 40, 0.95);
}

.root > h4 {
  margin: 0 0 5px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #afafaf;
}

.root p {
  font-size: 0.9rem;
  margin-top: 0;
}

.reference {
  text-decoration: none;
  color: cadetblue;
}
.invalid {
  text-decoration-line: line-through;
}
