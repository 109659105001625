.counter {
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 14px;
  font-weight: bold;
  /* background-color: white; */
  background: rgba(255, 255, 255, 0.85);
  /* backdrop-filter: blur(5px); */
  border-radius: 100%;
}

.tooltip > * {
  margin: 0;
  padding: 0;
}

.tooltip > :first-child {
  font-weight: 600;
}
