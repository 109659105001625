.root {
  position: fixed;
  top: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 50px; */
  /* background-color: #333; */
  user-select: none;
  pointer-events: none;
}

.navList {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  /* overflow: hidden; */
}

.navItem {
  display: flex;
  float: left;
  height: 48px;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  border: 1px solid #afafaf;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.navItem:hover > a {
  color: cadetblue;
  background: rgba(220, 220, 220, 0.5);
}

.navItem > a {
  /* padding: 15px 30px; */
  padding: 10px;
  font-weight: 500;
  color: rgba(60, 60, 60, 0.95);
  text-decoration: none;
}

.navItem > a:has(img) {
  padding: 5px;
  padding-right: 10px;
}

.link_active {
  text-decoration: solid underline cadetblue 2px !important;
}

.login {
  color: #fff;
  margin-right: 30px;
  text-decoration: none;
}

.login:hover {
  text-decoration: underline;
}

.icon > a {
  color: rgba(80, 80, 80, 0.95);
}

.icon > .link_active {
  color: cadetblue;
}

.navItem:first-child {
  height: auto;
}

.navItem:first-child > a {
  padding-bottom: 6px;
}

@media screen and (max-width: 450px) {
  .root {
    top: 5px;
  }

  .navList {
    gap: 5px;
    padding-left: 5px;
  }
  .navItem:first-child > a {
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
  }

  .navItem:first-child > a > svg {
    position: relative;
    top: 2px;
    width: 42px;
    height: 42px;
  }
}
