.root {
  display: flex;
  flex-direction: column;
  margin: 5px;
  color: rgba(60, 60, 60, 0.95);
  font-weight: 500;
}

.mainCategory {
  display: flex;
}

.subCategory {
  display: flex;
  flex-direction: row;
}

.checkbox {
  margin-right: 5px;
  accent-color: #555;
}

.accordionPanel {
  min-height: 50px;
  margin-top: 5px;
  margin-left: 40px;
}
