.root {
  max-width: 100%;
  margin: 10px;
  background: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.suggestion {
  width: 100%;
}

.suggestion a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: rgba(80, 80, 80, 0.95);
  text-decoration: none;
}

.suggestion:hover a,
.suggestion:active a {
  color: cadetblue;
}
