.root {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 200;
  bottom: 10px;
  transform: translateY(calc(-100% - 10px));
  height: 40%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  line-height: 1.4;
}

.root > * {
  flex: 3 1 0;
  margin: 15px;
  min-width: 225px;
}

.root form {
  flex: 2 1 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 0;
}

.root form > * {
  margin-bottom: 10px !important;
}

.root form input {
  width: 100%;
  /* max-width: 400px; */
}

.root form > fieldset,
.root form > div {
  display: flex;
  align-items: flex-end;
  /* max-width: 405px; */
  margin: 0;
  padding: 0;
  border: 0;
}

.root form > div > *,
.root form > fieldset > * {
  margin-right: 5px;
}

.root form > div > div,
.root form > fieldset > div {
  flex-basis: 100%;
}

.root fieldset button:first-child {
  margin-left: auto;
}

.root label {
  display: block;
}

.root p {
  font-size: 0.9rem;
  margin-top: 0;
}

.event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.contentContainer {
  height: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.detailsContainer {
  flex-grow: 0;
}

.title {
  width: 100%;
  margin: 0;
}

.description {
  height: 100%;
  overflow: auto;
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
  border-top: 1px solid #afafaf;
}

.details {
  padding: 0;
  margin: 0;
}

.details li {
  display: inline-block;
  margin-right: 10px;
  font-size: 0.9rem;
}

.details li:first-child {
  font-weight: 700;
}

.details li:not(:first-child):not(:last-child)::after {
  content: " | ";
  font-weight: 500;
  color: #afafaf;
}