.root {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.feedContainer {
  flex: 1 1 content;
  overflow: auto;
  margin: 10px 0 10px 10px;
}

.eventContainer {
  flex: 1 1 auto;
  margin: 10px 10px 10px 0;
}

.eventContainer > div {
  border-radius: 3px;
  border: 1px solid #afafaf;
}

.mapContainer {
  flex: 1 1 auto;
}
